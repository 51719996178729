<template>
	<div>
		<el-card class="box-card" ref="content">
			<div slot="header" class="clearfix">
				<span class="font-weight-bolder">基本信息</span>
			</div>
			<el-form :inline="true" :model="serviceData" :rules="serviceRules" ref="serviceRuleForm">
				<el-form-item label="客户姓名" prop="name" :label-width="formLabelWidth">
					<el-input v-model="serviceData.name" autocomplete="off" size="small">
					</el-input>
				</el-form-item>
				<el-form-item label="联系方式" prop="mobile" :label-width="formLabelWidth">
					<el-input v-model="serviceData.mobile" autocomplete="off" size="small">
					</el-input>
				</el-form-item>
				<el-form-item label="楼盘" prop="estate" :label-width="formLabelWidth">
					<el-input v-model="serviceData.estate" autocomplete="off" size="small">
					</el-input>
				</el-form-item>
				<el-form-item label="房号" prop="building" :label-width="formLabelWidth">
					<el-input v-model="serviceData.building" autocomplete="off" size="small">
					</el-input>
				</el-form-item>
				<el-form-item label="户型" prop="house_type" :label-width="formLabelWidth">
					<el-input v-model="serviceData.house_type" autocomplete="off" size="small" style="width: 50%;">
					</el-input>
				</el-form-item>
				<el-form-item label="面积" prop="area" :label-width="formLabelWidth">
					<el-input v-model="serviceData.area" autocomplete="off" size="small" style="width: 50%;">
					</el-input>
				</el-form-item>
				<el-form-item label="收货地址" prop="address" :label-width="formLabelWidth">
					<el-input v-model="serviceData.address" autocomplete="off" size="small" style="width: 100%;min-width: 500px;">
					</el-input>
				</el-form-item>
				<el-form-item label="预约日期" :label-width="formLabelWidth" prop="bookDate">
					<el-date-picker size="small" v-model="bookDate" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="选择日期" :clearable="false">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="预约时间段" :label-width="formLabelWidth" prop="bookTime">
					<el-radio-group v-model="bookTime">
						<el-radio label="9:00~12:00">9:00~12:00</el-radio>
						<el-radio label="14:00~18:00">14:00~18:00</el-radio>
					</el-radio-group>
				</el-form-item>
			</el-form>
		</el-card>
		<el-card class="box-card mt-4">
			<div slot="header" class="clearfix">
				<span class="font-weight-bolder">验收结果</span>
			</div>
			<el-form :inline="true" :model="serviceData" :rules="reportRules" ref="reportRuleForm">
				<el-form-item label="验收日期" :label-width="formLabelWidth" prop="service_time">
					<el-date-picker format="yyyy-MM-dd" v-model="serviceData.service_time" type="date" size="small" placeholder="选择日期">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="报告编号" :label-width="formLabelWidth" prop="report_no">
					<el-input v-model="serviceData.report_no" autocomplete="off" size="small" placeholder="请填写报告编号">
					</el-input>
				</el-form-item>
			</el-form>
			<el-tabs v-model="tabIndex" @tab-click="handleClick">
				<el-tab-pane v-for="(tab,tabI) in serviceData.detail_report" :key="tabI" :label="tab.name">
					<el-form style="width: 80%;">
						<el-form-item v-for="(item,index) in tab.subItem" :label="item.subname+':'" :label-width="formLabelWidth">
							<el-input :rows="5" type="textarea" v-model="item.result"
								:placeholder="item.desc | descToString"></el-input>
						</el-form-item>
						<el-form-item label="图片汇总:" prop="address" :label-width="formLabelWidth">
							<div class="d-flex">
								<div class="el-upload-list--picture-card d-flex" style="flex-wrap: wrap;">
									<div class="el-upload-list__item" style="position: relative;" v-for="(imgitem,imgindex) in tab.imgs" :key="imgindex">
										<el-image class="el-upload-list__item-thumbnail" :src="$conf.serverUrl+'storage/'+imgitem" :preview-src-list="imgList"></el-image>
										<div class="d-flex align-items-center justify-content-center p-1" style="position: absolute; top:0;right: 0;"
											 @click="uploadRemove(imgindex)">
											 <i class="el-icon-error text-white" style="font-size: 20px;"></i>
										</div>
									</div>
									<el-upload action="/admin/service/uploadimg"
											   :http-request="diyUpload"
											   :headers="{token:$store.state.user.token}"
											   name="image[]"
											   :show-file-list="false"
											   multiple list-type="picture-card"
											   :on-preview="uploadPreview"
											   :on-remove="uploadRemove"
											   :on-success="uploadSuccess"
											   :on-error="uploadError">
										<i class="el-icon-plus"></i>
									</el-upload>
								</div>
							</div>
						</el-form-item>
					</el-form>
				</el-tab-pane>
			</el-tabs>
		</el-card>
		<div style="width: 100%;height: 50px;"></div>
		<div class="bg-white rounded d-flex align-center" style="position: fixed;left: auto;bottom: 0;right: auto;z-index: 10;height: 4rem;box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);"
			 :style="'width: '+operationWidth+'px;'">
			<div class="w-100 d-flex justify-content-end p-3 pr-3" style="height: 100%;">
				<el-button class="mr-1" style="width: 10%;" size="small" @click="cancelClick">取消</el-button>
				<el-button style="width: 10%;" size="small" type="primary" @click="submit">保存</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		inject: ['layout'],
		data() {
			return {
				serviceId: '',
				serviceData: {},
				bookDate:'',
				bookTime:'',
				operationWidth:0,
				tabIndex: 0,
				formLabelWidth: '120px',
				serviceRules: {
					name: [{
						required: true,
						message: '请输入客户姓名',
						trigger: 'blur'
					}, ],
					mobile: [{
						required: true,
						message: '请输入手机号',
						trigger: 'blur'
					}],
					estate: [{
						required: true,
						message: '请输入楼盘',
						trigger: 'blur'
					}],
					building: [{
						required: true,
						message: '请输入房号',
						trigger: 'blur'
					}],
					house_type: [{
						required: true,
						message: '请输入户型',
						trigger: 'blur'
					}],
					area: [{
						required: true,
						message: '请输入面积',
						trigger: 'blur'
					}],
					address: [{
						required: true,
						message: '请输入收货地址',
						trigger: 'blur'
					}]
				},
				reportRules: {
					service_time: [{
						required: true,
						message: '请选择验收时间',
						trigger: 'blur'
					}, ],
					report_no: [{
						required: true,
						message: '请输入报告编号',
						trigger: 'blur'
					}]
				},
				deleteImgList:[],
			};
		},
		filters: {
			descToString(desc) {
				let str = desc ? '验收内容：' : ''
				for (var i = 0; i < desc.length; i++) {
					str = str + (i + 1) + '. ' + desc[i] + '   '
				}
				return str
			},
		},
		created() {
			if (this.$route.params.serviceId) {
				window.sessionStorage.setItem('targetId', this.$route.params.serviceId)
			}
			this.serviceId = parseInt(window.sessionStorage.getItem('targetId'))
			if (!this.serviceId) {
				this.$message({
					message: 'id丢失',
					type: 'error'
				});
			}
			this.getServiceData()
		},
		beforeDestroy() {
			window.sessionStorage.setItem('targetId', 0)
		},
		mounted() {
			this.operationWidth = this.$refs.content.$el.offsetWidth
		},
		computed:{
			imgList:function(){
				let list = []
				for(let v of this.serviceData.detail_report[this.tabIndex].imgs){
					list.push(this.$conf.serverUrl+'storage/'+v)
				}
				return list
			}
		},
		methods: {
			getServiceData() {
				this.layout.loading = true
				let url = '/admin/service/edit/' + this.serviceId
				this.axios.get(url, {
						token: true
					})
					.then((res) => {
						console.log(res)
						this.serviceData = res.data.data
						if(this.serviceData.book_time){
							this.bookDate = this.serviceData.book_time.substr(0,10)
							this.bookTime = this.serviceData.book_time.substr(11)
						}
						this.layout.loading = false
					})
					.catch((err) => {
						console.log(err)
					})
			},
			diyUpload(file) {
				let fileData = new FormData()
				fileData.append('image', file.file);
				let url = file.action
				this.axios.post(url, fileData,{
						token: true
					})
					.then((res) => {
						this.serviceData.detail_report[this.tabIndex].imgs.push(res.data.data.imgs)
					})
					.catch((err) => {
						console.log(err)
					})
			},
			uploadRemove(imgindex) {
				let img = this.serviceData.detail_report[this.tabIndex].imgs[imgindex]
				//将图片添加进待删除列表
				this.deleteImgList.push(img)
				//将图片从汇总列表中删除
				this.serviceData.detail_report[this.tabIndex].imgs.splice(imgindex,1)
			},
			uploadPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			uploadSuccess(res, file, fileList) {
				console.log(res)
				console.log(file)
				console.log(fileList)
				this.serviceData.detail_report[this.tabIndex].imgs.push(res.data.imgs[0])
			},
			uploadError(err, file, fileList) {
				console.log(err)
			},
			submit() {
				this.serviceData.bookDate = this.bookDate
				this.serviceData.bookTime = this.bookTime
				this.$refs['serviceRuleForm','reportRuleForm'].validate((valid) => {
					if (valid) {
						let url = '/admin/service/update/' + this.serviceId
						this.serviceData.book_time = this.serviceData.bookDate+' '+this.serviceData.bookTime
						this.axios.post(url, this.serviceData, {
								token: true
							})
							.then((res) => {
								this.$message({
									message: '保存成功',
									type: 'success'
								})
								if(this.deleteImgList.length > 0){
									this.deleteImgs()
								}
							})
							.catch((err) => {
								this.$message.error({
									message: '保存失败，服务器请求出错'
								})
							})
					}else{
						this.$message.warning({
							message: '保存失败，缺少必填项'
						})
					}
				})
			},
			deleteImgs() {
				console.log(this.deleteImgList)
				let url = '/admin/service/deleteimg'
				this.axios.post(url, {delimgs:this.deleteImgList}, {
							token: true
						})
						.then((res) => {
							console.log(res)
						})
						.catch((err) => {
							console.log(err)
						})
			},
			handleClick(tab, event) {
				console.log(tab, event);
			},
			cancelClick() {
				this.$router.back()
			}
		}
	};
</script>

<style>
</style>
